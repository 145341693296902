@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-layout-sider-trigger{
    background: white!important;
    color: black!important;
}

.ant-card-head {
    background: #fafafa!important;
}

.hover-pointer:hover {
    cursor: pointer!important;
}

.font-size-15 {
    font-size: 15px!important;
}

.font-size-25 {
    font-size: 25px!important;
}

.h-opacity:hover {
    opacity: 0.6!important;
}

.bg-warning {
    background-color: #ffff66!important;
}

.text-warning {
    color: #ffff66!important;
}

.info {
    color: #d9edf7!important;
}

.info-bg {
    background: #d9edf7!important;
}

.greenSuperSlow {
    color: #ffffff;
    background-color: #64ba6d!important;
    border-color: #4cae4c!important;
}

.danger-bg {
    background-color: #ff7777!important;
    color : #fff!important;
}

.ant-popconfirm-buttons .ant-btn-primary{
    background-color: #ff7777!important;
}

.table-r{
    height: 75px;
    line-height: 14px;
}

@media screen and (max-width: 600px) {
    .table-r{
        height: 100px;
    }
}